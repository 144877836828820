import React, { Component }  from 'react';
import images from '../../res/images';
import {
    Box,
    Card,
    Image,
    Heading,
    Text,
    Flex
  } from 'rebass';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faFireAlt, faEgg, faDrumstickBite } from '@fortawesome/free-solid-svg-icons'

export default class MenuItem extends Component {
    render() {
        return (
            <div>
                <Flex justifyContent='space-between'>
                    <Flex>
                        <Box>
                            <Text fontWeight='bold'>{this.props.item.name.toUpperCase()}</Text>
                        </Box>

                        {this.props.item.warnRaw &&
                            <Box px={2}>
                                <FontAwesomeIcon icon={faDrumstickBite} />
                            </Box>
                        }
                        {this.props.item.warnEgg &&
                            <Box px={2}>
                                <FontAwesomeIcon icon={faEgg} />
                            </Box>
                        }
                        {this.props.item.warnSpicy &&
                            <Box px={2}>
                                <FontAwesomeIcon icon={faFireAlt} />
                            </Box>
                        }
                    </Flex>

                    <Text pr={[3, 6]} fontWeight='bold'>{this.props.item.price}</Text>

                </Flex>

                <Box>
                    <Text>{this.props.item.description}</Text>
                </Box>
            </div>
        )
    }

};