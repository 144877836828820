import React, { Component }  from 'react';
import images from '../../res/images';
import {
    Box,
    Card,
    Image,
    Heading,
    Text,
    Flex
  } from 'rebass';
import { Home, Menu, About } from '../index'

export default class Main extends Component {

    getPage() {
        if (this.props.page === "menu") {
            return (<Menu />)
        }
        else if (this.props.page === "about") {
            return (<About />)
        } else {
            return (<Home />)
        }
    }

    render() {
        return (
            <div>
                { this.getPage() }
            </div>
        )
    }
}
