export default [
    {"name": "Ribeye", "menuType":"food", "category": "Entrees", "price": "45", "available": true, "warnRaw": true, "warnEgg": false, "warnSpicy": false,
        "description": "Handcut U.S.D.A. choice ribeye, seasoned with a blend of rosemary, thyme, sage and toasted black peppercorn, char-broiled, garnished with roasted red bell pepper oil."},
    {"name": "Filet", "menuType":"food", "category": "Entrees", "price": "45", "available": true, "warnRaw": true, "warnEgg": false, "warnSpicy": false,
        "description": "Handcut U.S.D.A. choice tenderloin filet, char-broiled and finished with our whiskey glace."},
    {"name": "Walleye", "menuType":"food", "category": "Entrees", "price": "33", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "Wild caught Canadian walleye filet pan-fried and garnished with a bacon, mushroom and scallion confetti."},
    {"name": "Norwegian Salmon", "menuType":"food", "category": "Entrees", "price": "35", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "Norwegian salmon filet seasoned with a blend of rosemary, thyme, sage and toasted black peppercorn, char-broiled and served with a balsamic syrup accent."},
    {"name": "Lamb Shank", "menuType":"food", "category": "Entrees", "price": "39", "available": true, "warnRaw": true, "warnEgg": false, "warnSpicy": false,
        "description": "A hearty Australian lamb shank braised with rosemary, garlic, wine, and tomatoes."},
    {"name": "Stuffed Pork Chop", "menuType":"food", "category": "Entrees", "price": "35", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "A double thick bone-in pork shop stuffed with a blend of cornbread, dried cherries, chopped walnuts and sage.  Char-broiled and served with a sauce bechamela."},
    {"name": "Scallopini Marsala", "menuType":"food", "category": "Entrees", "price": "33", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "Pork scallopini sauteed with fresh mushrooms and finish with Marsala wine and stock."},
    {"name": "Duck Madeira", "menuType":"food", "category": "Entrees", "price": "38", "available": true, "warnRaw": true, "warnEgg": false, "warnSpicy": false,
        "description": "Duck breast pan-seared medium-rare, finished with grapes, duck stock and Madeira wine."},

    {"name": "Mostaccioli Rapido", "menuType":"food", "category": "Italian Favorites", "price": "23", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": true,
        "description": "Garlic, olive oil, crushed red pepper, parsley and basil tossed with mostaccioli pasta."},
    {"name": "Spaghetti Bolognese", "menuType":"food", "category": "Italian Favorites", "price": "27", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "Our recipe for this traditional ragu from Emilia Romagna, fresh ground beef and pork, onion, garlic, tomato, herbs and spices."},
    {"name": "Calamari Diavolo", "menuType":"food", "category": "Italian Favorites", "price": "28", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": true,
        "description": "Tender calamari steamed with tomatoes, olive oil, garlic, herbs and spices, served over fettuccine pasta."},
    {"name": "Shrimp Scampi", "menuType":"food", "category": "Italian Favorites", "price": "33", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "Shrimp sauteed with mushrooms and scallions, finished with white wile and our scampi butter sauce, served over fettuccine pasta."},
    {"name": "Lobster Risotto", "menuType":"food", "category": "Italian Favorites", "price": "38", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "Char-broiled lobster tail garnished with toasted pine nuts, fresh parsley and lemon zest, served over rich lobster risotto."},
    {"name": "Fettuccine Alfredo", "menuType":"food", "category": "Italian Favorites", "price": "25", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "Fettuccine pasta with a rich cream sauce and paresan cheese, garnished with freshly cracked black pepper."},
    {"name": "Chicken and Spinach Ravioli", "menuType":"food", "category": "Italian Favorites", "price": "27", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "Tender pasta pillows filled with chicken, spinach and Italian chesse, browned and served in a sage brown butter sauce."},
    {"name": "Lasagna al Forno", "menuType":"food", "category": "Italian Favorites", "price": "27", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "A classic Italian American baked lasagna with layers of pasta, meat sauce, ricotta cheese and a rich bechamela."},
    {"name": "Polpetti", "menuType":"food", "category": "Italian Favorites", "price": "27", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "Spaghetti pasta served with marinara and meatballs."},
    {"name": "Chicken Parmesan", "menuType":"food", "category": "Italian Favorites", "price": "25", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "Boneless skinless chicken breast seasoned with bread crumbs, baked and finished with melted mozzarella cheese, served with spaghetti pasta and marinara."},

    {"name": "Calamari Fritto", "menuType":"food", "category": "Appetizers", "price": "15", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "Tender calamari, dusted with seasoned flour and flash fried, served with our lemon aioli."},
    {"name": "Tre Bruschetti", "menuType":"food", "category": "Appetizers", "price": "12", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "Grilled country bread finished with a trio of toppings; eggplant caponata, olive tapenade, and white bean salad."},
    {"name": "Pesto Torta", "menuType":"food", "category": "Appetizers", "price": "12", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "Layers of soft cheeses and our basil pesto, sprinkled with toasted walnuts and chive.  Served with lavosh and country bread."},
    {"name": "Mushroom Fonduta", "menuType":"food", "category": "Appetizers", "price": "12", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "Mushroom caps stuffed with a blend of goat cheese, gorgonzola, and toasted walnuts.  Served over a parmesan fonduta."},
    {"name": "Shrimp Spiedini", "menuType":"food", "category": "Appetizers", "price": "15", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "Skewered shrimp, char-broiled and topped with our basil pesto.  Served with fresh lemon."},
    {"name": "Tuna Carpaccio", "menuType":"food", "category": "Appetizers", "price": "18", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "'Sushi' grade yellowfin tuna seared on the outside and raw on the inside, sliced thin and seasoned with olive oil, fresh ground pepper and a balsamic accent."},

    {"name": "Caesar", "menuType":"food", "category": "Salads", "price": "12", "available": true, "warnRaw": false, "warnEgg": true, "warnSpicy": false,
        "description": "Our own Caesar dressing tossed with fresh romaine lettuce, croutons, and parmesan cheese, garnished with anchovy."},
    {"name": "Garden Greens", "menuType":"food", "category": "Salads", "price": "8", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "A blend of fresh garden leaves, garnished with vegetables and/or fruit.  With the choice of the following dressings: Italian, bleu cheese, honey mustard, sun-dried tomato, basil vinaigrette, or olive oil and balsamic vinegar."},

    {"name": "Beer Cheese Soup", "menuType":"food", "category": "Soups", "price": "", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "Rich creamy cheddar cheese soup flavored with bacon, onion and beer."},
    {"name": "Today's Selection", "menuType":"food", "category": "Soups", "price": "", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "Ask your server for details about today's feature soup."},

    {"name": "Creme Brulee", "menuType":"food", "category": "Desserts", "price": "", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "A creamy vanilla custard with a crisp sugar glaze."},
    {"name": "Chocolate Zabaglione", "menuType":"food", "category": "Desserts", "price": "", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "Rich chocolate custard made with Marsala and Prosecco, topped with fresh whipped cream and an amaretti cookie crumble."},
    {"name": "Tiramisu", "menuType":"food", "category": "Desserts", "price": "", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "Layers of marscapone on espresso dipped lady fingers."},
    {"name": "Cannoli", "menuType":"food", "category": "Desserts", "price": "", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "Crisp pastry shell filled with sweetened citrus ricotta served with cherry coulis and toasted pistachios."},
    {"name": "Cheesecake", "menuType":"food", "category": "Desserts", "price": "", "available": true, "warnRaw": false, "warnEgg": false, "warnSpicy": false,
        "description": "Ask your server for current flavor."}
];