import React, { Component }  from 'react';
import images from '../../res/images';
import {
    Box,
    Card,
    Image,
    Heading,
    Text,
    Flex
  } from 'rebass';

export default class DrinkMenu extends Component {
    render() {
        return (
            <div>
                <Flex>
                    
                </Flex>
            </div>
        )
    }
};