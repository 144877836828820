import React, { Component }  from 'react';
import images from '../../res/images';
import {
    Box,
    Card,
    Image,
    Heading,
    Text,
    Flex
  } from 'rebass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFireAlt, faEgg, faDrumstickBite } from '@fortawesome/free-solid-svg-icons'
import MenuData from './MenuData';
import MenuItem from './MenuItem';

export default class FoodMenu extends Component {
    
    render() {
        return (
            <div>
                <Box textAlign='center'>
                    <Box width='90%' margin='auto'>
                        <Flex flexDirection='column' textAlign='center'>

                            <Box height='2px' backgroundColor='black' />
                            <Text px={3} fontWeight='bold' fontSize='175%' textAlign='left'>
                                ENTREES
                            </Text>
                            <Box height='2px' backgroundColor='black' />
                            <Box px={3} width={[1, 1/2]}>
                                <Text textAlign='left' fontStyle='italic'>
                                    All entrees are offered with a choice of our roasted garlic mashed potatoes, french fries or today's vegetable as well as a choice of garden greens salad or one of our handmade soups.
                                </Text>
                            </Box>
                            <Flex px={3} width={[1, 1/2]} justifyContent='space-between'>
                                <Text textAlign='left' fontWeight='bold' fontStyle='italic'>Substitute a Caesar salad</Text>
                                <Text pr={[3, 6]} fontWeight='bold'>4</Text>
                            </Flex>

                            <Flex py={3} flexWrap='wrap' textAlign='center'>
                                { MenuData.map((e) => (e.category === "Entrees") ? 
                                    <Box px={3} pb={3} width={[1, 1/2]} textAlign='left'><MenuItem  key={e.name.toString()} item={e} /></Box> : '')}
                            </Flex>

                            <Box height='2px' backgroundColor='black' />
                            <Text px={3} fontWeight='bold' fontSize='175%' textAlign='left'>
                                ITALIAN FAVORITES
                            </Text>
                            <Box height='2px' backgroundColor='black' />
                            <Box px={3} width={[1, 1/2]}>
                                <Text textAlign='left' fontStyle='italic'>
                                    All Italian favorites include a choice of garden greens salad or one of our handmade soups.
                                </Text>
                            </Box>
                            <Flex px={3} width={[1, 1/2]} justifyContent='space-between'>
                                <Text textAlign='left' fontWeight='bold' fontStyle='italic'>Substitute a Caesar salad</Text>
                                <Text pr={[3, 6]} fontWeight='bold'>4</Text>
                            </Flex>

                            <Flex pt={3} flexWrap='wrap' textAlign='center'>
                                { MenuData.map((e) => (e.category === "Italian Favorites") ? 
                                    <Box px={3} pb={3} width={[1, 1/2]} textAlign='left'><MenuItem key={e.name.toString()} item={e} /></Box> : '')}
                            </Flex>

                            <Text px={3} textAlign='left' fontWeight='bold' fontStyle='italic'>Add the following to the above favorites:</Text>
                            <Flex px={3} width={[1, 1/2]} justifyContent='space-between'>
                                <Text textAlign='left' fontWeight='bold' fontStyle='italic'>Grilled Italian Sausage</Text>
                                <Text pr={[3, 6]} fontWeight='bold'>7</Text>
                            </Flex>
                            <Flex px={3} width={[1, 1/2]} justifyContent='space-between'>
                                <Text textAlign='left' fontWeight='bold' fontStyle='italic'>Grilled Chicken</Text>
                                <Text pr={[3, 6]} fontWeight='bold'>7</Text>
                            </Flex>
                            <Flex px={3} width={[1, 1/2]} justifyContent='space-between'>
                                <Text textAlign='left' fontWeight='bold' fontStyle='italic'>Meatballs</Text>
                                <Text pr={[3, 6]} fontWeight='bold'>7</Text>
                            </Flex>
                            <Flex px={3} width={[1, 1/2]} justifyContent='space-between'>
                                <Text textAlign='left' fontWeight='bold' fontStyle='italic'>Pesto Shrimp</Text>
                                <Text pr={[3, 6]} fontWeight='bold'>15</Text>
                            </Flex>
                            <Flex px={3} width={[1, 1/2]} justifyContent='space-between'>
                                <Text textAlign='left' fontWeight='bold' fontStyle='italic'>Grilled Salmon</Text>
                                <Text pr={[3, 6]} fontWeight='bold'>20</Text>
                            </Flex>
                            <Flex pb={3} px={3} width={[1, 1/2]} justifyContent='space-between'>
                                <Text textAlign='left' fontWeight='bold' fontStyle='italic'>Grilled Lobster Tail</Text>
                                <Text pr={[3, 6]} fontWeight='bold'>20</Text>
                            </Flex>

                            <Box height='2px' backgroundColor='black' />
                            <Text px={3} fontWeight='bold' fontSize='175%' textAlign='left'>
                                APPETIZERS
                            </Text>
                            <Box height='2px' backgroundColor='black' />

                            <Flex py={3} flexWrap='wrap' textAlign='center'>
                                { MenuData.map((e) => (e.category === "Appetizers") ? 
                                    <Box px={3} pb={3} width={[1, 1/2]} textAlign='left'><MenuItem key={e.name.toString()} item={e} /></Box> : '')}
                            </Flex>

                            <Flex flexWrap='wrap' textAlign='center'>
                                <Box width={[1, 1/2]}>
                                    <Box height='2px' backgroundColor='black' />
                                    <Text px={3} fontWeight='bold' fontSize='175%' textAlign='left'>
                                        SALADS
                                    </Text>
                                    <Box height='2px' backgroundColor='black' />

                                    <Flex py={3} flexWrap='wrap' textAlign='center'>
                                        { MenuData.map((e) => (e.category === "Salads") ? 
                                            <Box px={3} pb={3} textAlign='left'><MenuItem key={e.name.toString()} item={e} /></Box> : '')}
                                    </Flex>
                                </Box>

                                <Box width={[1, 1/2]}>
                                    <Box height='2px' backgroundColor='black' />
                                    <Text px={3} fontWeight='bold' fontSize='175%' textAlign='left'>
                                        SOUPS
                                    </Text>
                                    <Box height='2px' backgroundColor='black' />
                                    <Flex px={3} width={[1, 1/2]} justifyContent='space-between'>
                                        <Text fontWeight='bold' textAlign='left'>Cup  8</Text>
                                        <Text fontWeight='bold' pr={[3, 6]}>Bowl  10</Text>
                                    </Flex>

                                    <Flex py={3} flexWrap='wrap' textAlign='center'>
                                        { MenuData.map((e) => (e.category === "Soups") ? 
                                            <Box px={3} pb={3} textAlign='left'><MenuItem key={e.name.toString()} item={e} /></Box> : '')}
                                    </Flex>
                                </Box>
                            </Flex>

                            <Box height='2px' backgroundColor='black' />
                            <Text px={3} fontWeight='bold' fontSize='175%' textAlign='left'>
                                DESSERTS
                            </Text>
                            <Box height='2px' backgroundColor='black' />
                            <Flex px={3} width={[1, 1/2]} justifyContent='space-between'>
                                <Text fontWeight='bold' textAlign='left'>ALL HOUSEMADE DESSERTS</Text>
                                <Text fontWeight='bold' pr={[3, 6]}>9</Text>
                            </Flex>

                            <Flex py={3} flexWrap='wrap' textAlign='center'>
                                { MenuData.map((e) => (e.category === "Desserts") ? 
                                    <Box px={3} pb={3} width={[1, 1/2]} textAlign='left'><MenuItem key={e.name.toString()} item={e} /></Box> : '')}
                            </Flex>

                            <Box height='2px' backgroundColor='black' />

                            <Text><FontAwesomeIcon icon={faDrumstickBite} /> consuming raw or undercooked (rare, medium-rare, or medium) foods may increase your risk of food-borne illness</Text>
                            <Text><FontAwesomeIcon icon={faEgg} /> contains raw egg</Text>
                            <Text><FontAwesomeIcon icon={faFireAlt} /> Spicy</Text>

                            <Text fontSize={1}>An 18% service charge will be added to all parties of six or more.</Text>
                            <Text fontSize={1}>Carry-in food and beverage prohibited.</Text>
                            <Text fontSize={1}>Menu and prices are subject to change.</Text>

                            <Box height='2px' backgroundColor='black' />

                        </Flex>
                    </Box>
                </Box>
            </div>
        )
    }
};