import React, { Component }  from 'react';
import images from '../../res/images';
import {
    Box,
    Card,
    Image,
    Heading,
    Text,
    Flex,
    Link
  } from 'rebass';

export default class Home extends Component {
    render() {
        const splashImageHeight = window.innerHeight - (window.innerHeight * .40);
        const qrImageHeight = window.innerHeight - (window.innerHeight * .80);

        return (
            <div>
                <Box width={[1, .8]} textAlign='center' margin='auto' padding={10}>
                    <Image sx={{'object-fit': 'cover'}} height={splashImageHeight} src={images.frontDoor} />
                </Box>
                <Box width={[1, .8]} textAlign='center' margin='auto' padding={10}>
                    <Link href='https://forms.gle/4pFJ6LU2AqVHCG7UA'><Image sx={{'object-fit': 'cover'}} height={qrImageHeight} src={images.hiringQrSticker} /></Link>
                </Box>
            </div>
        )
    }
};
