import React, { Component }  from 'react';
import images from '../../res/images';
import {
    Box,
    Card,
    Image,
    Heading,
    Text,
    Flex,
    Link
  } from 'rebass';

export default class Header extends Component {

    render() {
        return (
            <div>
                <Flex flexWrap='wrap'>
                    <Box width={[ 1, 1/3 ]} p={3}>
                        <Text 
                        css="cursor: pointer;" 
                        fontSize='200%' 
                        fontWeight={(this.props.page === "home") ? "bold" : "normal"}
                        onClick={(e) => this.props.updateAppStatePage("home")}>Home</Text>
                    </Box>
                    <Box width={[ 1, 1/3 ]} p={3}>
                        <Text 
                        css="cursor: pointer;" 
                        fontSize='200%' 
                        fontWeight={(this.props.page === "about") ? "bold" : "normal"}
                        onClick={(e) => this.props.updateAppStatePage("about")}>About</Text>
                    </Box>
                    <Box width={[ 1, 1/3 ]} p={3}>
                        <Text 
                        css="cursor: pointer;" 
                        fontSize='200%' 
                        fontWeight={(this.props.page === "menu") ? "bold" : "normal"}
                        onClick={(e) => this.props.updateAppStatePage("menu")}>Menu</Text>
                    </Box>
                </Flex>
            </div>
        )
    }
};
