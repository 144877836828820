import React, { Component }  from 'react';
import images from '../../res/images';
import {
    Box,
    Card,
    Image,
    Heading,
    Text,
    Flex
  } from 'rebass';
import MenuData from './MenuData';
import FoodMenu from './FoodMenu';
import DrinkMenu from './DrinkMenu';

export default class Menu extends Component {

    constructor(props) {
        super(props);
        this.state = {
          menuType: "food"};
    }
    updateMenuStateMenuType = (menuType) => {this.setState( { menuType })}

    menuHeader() {
        return (
        <Flex flexWrap='wrap' textAlign='top'>
            <Box width={[ 1, 1/2 ]} p={3} textAlign={['center', 'right']}>
                <Text 
                css="cursor: pointer;"
                fontSize='175%'
                fontWeight={(this.state.menuType === "food") ? "bold" : "normal"}
                onClick={(e) => this.updateMenuStateMenuType("food")}>Food</Text>
            </Box>
            {/* <Box width={[ 1, 1/2 ]} p={3} textAlign={['center', 'left']}>
                <Text 
                css="cursor: pointer;"
                fontSize='175%'
                fontWeight={(this.state.menuType === "drink") ? "bold" : "normal"}
                onClick={(e) => this.updateMenuStateMenuType("drink")}>Drink</Text>
            </Box> */}
        </Flex>
        );
    }

    menuPage() {
        // if (this.state.menuType === 'food') {
        //     return ( <FoodMenu /> );
        // } else if (this.state.menuType === 'drink') {
        //     return ( <DrinkMenu /> );
        // } else {
        //     return ( <FoodMenu /> );
        // }
        return ( <FoodMenu /> );
    }

    render() {
        return (
            <div>
                { this.menuHeader() }
                { this.menuPage() }
            </div>
        )
    }
};
