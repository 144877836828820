import React from 'react'
import { filter } from 'lodash/collection'
import { get } from 'lodash/object'
import './App.css'
import { Helmet } from 'react-helmet'
import images from './res/images'
import {
  Box,
  Card,
  Image,
  Heading,
  Text,
  Flex
} from 'rebass'
import { Main, Header, Footer } from './components/index'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "home",
      menuType: "food"};
    // this.updateAppStatePage = this.updateAppStatePage
    // this.updateAppStateMenuType = this.updateAppStateMenuType
  }
  updateAppStatePage = (page) => {this.setState( { page })}
  updateAppStateMenuType = (menuType) => {this.setState( { menuType })}

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <Flex minHeight='100vh' textAlign='center' flexDirection='column' justifyContent='space-between'>
        <Box by={2}>
          <Header page={this.state.page} updateAppStatePage={this.updateAppStatePage} />
        </Box>
        <Box>
          <Main page={this.state.page} />
        </Box>
        <Box marginTop={(this.state.page === 'menu') ? 'auto' : 'initial'}>
          <Footer />
        </Box>
      </Flex>
    );
  }
}

export default App;
