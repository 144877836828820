import React, { Component }  from 'react';
import images from '../../res/images';
import {
    Box,
    Card,
    Image,
    Heading,
    Text,
    Flex
  } from 'rebass';

export default class About extends Component {
    render() {
        const familyImageHeight = window.innerHeight - (window.innerHeight * .25);

        return (
            <div>
                <Box width={[1, .8]} textAlign='center' margin='auto' padding={10}>
                    <Image sx={{'object-fit': 'cover'}} height={familyImageHeight} src={images.family} />
                </Box>
                <Box textAlign='center'>
                    <Text width={[.8,.5]} margin='auto'>
                        Trattoria Dal Santo offers diners a unique experience of Northern Italian cooking along with fine wines and beer served in a comfortable, casual atmosphere.  
                        Come visit Sturgeon Bay's romantic, moderately priced, Italian restaurant for the ambiance, for the food, for the wine, and for that little touch of Italy in Door County.
                    </Text>
                </Box>
            </div>
        )
    }
};
