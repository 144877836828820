import React, { Component }  from 'react';
import images from '../../res/images';
import {
    Box,
    Card,
    Image,
    Heading,
    Text,
    Flex,
    Link
  } from 'rebass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'

export default class Footer extends Component {
    render() {
        const globeIconStyle = {
            paddingRight: '.5em',
        }
        const delimStyle = {
            fontWeight: 'bold',
        }
        const delim = (<span style={delimStyle}> | </span>)
        const phone = "920.743.6100"
        const street = "117 North 3rd Avenue"
        const city = "Sturgeon Bay, Wisconsin"
        const zip = "54235"

        return (
            <div>
                <Flex flexWrap='wrap' alignItems='baseline'>
                    <Box px={3} pb={3} width={[1, 1/2]} textAlign={["center", "left"]} verticalAlign="center">
                        <Text>
                            <Link href="https://www.google.com/maps/place/Trattoria+Dal+Santo/@44.8351683,-87.3776419,15z/data=!4m5!3m4!1s0x0:0x9c653c7363e62cd7!8m2!3d44.8351683!4d-87.3776419">
                                <span style={globeIconStyle}><FontAwesomeIcon icon={faGlobeAmericas} /></span>
                                {phone}{delim}
                                {street}{delim}
                                {city}{delim}
                                {zip}
                            </Link>
                        </Text>
                    </Box>
                    <Box px={3} pb={3} width={[1, 1/2]} textAlign={["center", "right"]}>
                        <Link href="https://www.facebook.com/TrattoriaDalSanto/" px={2}>
                            <FontAwesomeIcon size="2x" icon={faFacebookSquare} />
                        </Link>
                        <Link href="https://www.instagram.com/trattoriadalsanto/" px={2}>
                            <FontAwesomeIcon size="2x" icon={faInstagram} />
                        </Link>
                        <Link href="https://twitter.com/dalsantosrest" px={2}>
                            <FontAwesomeIcon size="2x" icon={faTwitter} />
                        </Link>
                    </Box>
                </Flex>
            </div>
        )
    }
};
